<template>
  <div class="details">
    <div class="details-top">
      <TitleBox :titlename="'采集异常数据详情'" />
      <div class="closebox" @click="falsePositivesbtn()">
        <p class="el-icon-close"></p>
        <p>关闭</p>
      </div>
    </div>
    <div class="details-sweiche">
      <div :class="{ action: isAction == index }" v-for="(item, index) in titlelist" :key="index"
        @click="swArea(index)">
        {{item}}
      </div>
    </div>
    <div class="details-center">
      <div class="typebox" v-show="isAction === 0">
        <div class="footerbox">
          <p>{{waterEnlist.Ct}}</p>
          <p>电导率异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.Ddo}}</p>
          <p>溶解氧异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.Ph}}</p>
          <p>PH值异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.Temp}}</p>
          <p>水温异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.Tur}}</p>
          <p>浊度异常</p>
        </div>
      </div>
      <div class="typebox" v-show="isAction === 1">
        <div class="footerbox">
          <p>{{waterEnlist.Flow}}</p>
          <p>流量异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.Height}}</p>
          <p>水深异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.Speed}}</p>
          <p>流速异常</p>
        </div>
      </div>
      <div class="typebox" v-show="isAction === 2">
        <div class="footerbox">
          <p>{{waterEnlist.Temp}}</p>
          <p>温度异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.WindDirection}}</p>
          <p>风向异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.WindSpeed}}</p>
          <p>风速异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.WindRHumidity}}</p>
          <p>湿度异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.WindRainfall}}</p>
          <p>降水量异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.WindAtmosphere}}</p>
          <p>气压异常</p>
        </div>
      </div>
      <div class="typebox" v-show="isAction === 3">
        <div class="footerbox">
          <p>{{waterEnlist.Flow}}</p>
          <p>流量异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.Height}}</p>
          <p>水深异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.Speed}}</p>
          <p>流速异常</p>
        </div>

      </div>
      <div class="typebox" v-show="isAction === 4">
        <div class="footerbox">
          <p>{{waterEnlist.Ct}}</p>
          <p>电导率异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.Ddo}}</p>
          <p>溶解氧异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.Ph}}</p>
          <p>PH值异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.Temp}}</p>
          <p>水温异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.Tur}}</p>
          <p>浊度异常</p>
        </div>
      </div>
      <div class="typebox" v-show="isAction === 5">
        <div class="footerbox">
          <p>{{waterEnlist.Ct}}</p>
          <p>电导率异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.Ddo}}</p>
          <p>溶解氧异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.Ph}}</p>
          <p>PH值异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.Temp}}</p>
          <p>水温异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.Tur}}</p>
          <p>浊度异常</p>
        </div>
      </div>
      <div class="typebox" v-show="isAction === 6">
        <div class="footerbox">
          <p>{{waterEnlist.Temp}}</p>
          <p>温度异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.WindDirection}}</p>
          <p>风向异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.WindSpeed}}</p>
          <p>风速异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.WindRHumidity}}</p>
          <p>湿度异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.WindRainfall}}</p>
          <p>降水量异常</p>
        </div>
        <div class="footerbox">
          <p>{{waterEnlist.WindAtmosphere}}</p>
          <p>气压异常</p>
        </div>
      </div>
    </div>
    <!-- <div class="btnbox">
      <el-button size="mini" class="falsePositivesbtn" type="primary" @click="falsePositivesbtn()">
        取消</el-button>
      <el-button size="mini" class="affirm" type="primary">确认</el-button>
    </div> -->
  </div>
</template>

<script>
import TitleBox from '../visualization/components/title/index.vue'
export default {
  props: ['detailsshow', 'diacollectionlist'],
  components: {
    TitleBox
  },
  data () {
    return {
      isAction: 0,
      titlelist: ['水质玻璃厂', '水文玻璃厂', '气象玻璃厂', '水文江滩公园', '水质江滩公园', '水质石矶头', '气象江滩公园'],
      waterEnlist: [],
      sweichecollectionlist: []
    }
  },
  computed: {},
  watch: {
    diacollectionlist: {
      deep: true,
      immediate: true,
      handler (newvalue, lodvalue) {
        this.$nextTick(() => {
          this.sweichecollectionlist = newvalue
          // console.log(this.sweichecollectionlist, 'watch')
          this.swArea(0)
        })
      }
    }
  },
  methods: {
    swArea (index) {
      // console.log(index, this.sweichecollectionlist, '-=-')
      this.isAction = index
      this.waterEnlist = this.sweichecollectionlist[index].exceptionsDataMap
      // console.log(this.waterEnlist, index, 'zizujianop')
    },
    falsePositivesbtn () {
      this.$emit('falsePositivesbtn')
    }

  },
  created () {
  }
}
</script>
<style lang="less" scoped>
.details {
  width: 50%;
  height: 60%;
  position: absolute;
  top: 20%;
  left: 25%;
  // background: url('../../assets/homeimg/k2.png') no-repeat;
  background: #0a2237;
  background-size: 100% 100%;
  overflow: hidden;
  border: 2px solid #59a5ff;
  z-index: 3;
  &-top {
    width: 92%;
    height: 60px;
    padding: 20px;
    margin-left: 4%;
    // border-bottom: 1px solid #6ca0c7;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    .closebox {
      width: 60px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      font-size: 14px;
      text-align: center;
      font-family: SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
    }
  }
  /deep/.el-input__inner {
    background-color: rgba(83, 118, 161, 0.4);
    color: #bfdafd;
    border: none;
    font-size: 12px;
    border-radius: 0px;
  }
  &-sweiche {
    width: 92%;
    height: 10%;
    margin-left: 4%;
    display: flex;
    justify-content: space-between;
    div {
      width: 24%;
      height: 30px;
      font-size: 16px;
      margin-top: 10px;
      font-family: SourceHanSansCN;
      font-weight: bold;
      color: #a5b7bf;
      text-align: center;
      line-height: 30px;
    }
    div.action {
      background: url('../../assets/homeimg/bt.png') no-repeat;

      background-size: 100% 100%;
      color: #fff;
    }
    // background: red;
  }
  &-center {
    // background:#59a5ff;
    width: 92%;
    height: 70%;
    // border-bottom: 1px solid #6ca0c7;
    margin-left: 4%;
    font-size: 18px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;

    .typebox {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      // display: ;
      .footerbox {
        width: 20%;
        height: 16%;
        margin-left: 10%;
        background: url('../../assets/homeimg/image@2x.png') no-repeat;
        background-size: 100% 100%;
        text-align: center;
        font-size: 18px;
        font-family: SourceHanSansCN;
        font-weight: 400;
        color: #fff558;
        overflow: hidden;

        p:nth-child(2) {
          font-size: 14px;
          color: #e6fcff;
          margin-top: -10%;
        }
      }
    }
  }
  .btnbox {
    width: 100%;
    height: 30%;

    .el-button {
      margin-left: 5%;
      height: 30px;
      margin-top: 5%;
    }
    .falsePositivesbtn {
      border: 1px solid #6da2da;
      background: transparent;
      margin-left: 70%;
    }
  }
}
</style>
